import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">


<path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m1947 908 c29 -6 50 -14 48 -19 -3 -4 10 -7 28 -6 37 1 159 -51 151
-64 -3 -5 4 -9 16 -9 50 0 293 -230 275 -260 -3 -4 2 -11 11 -14 18 -7 69
-116 79 -169 6 -28 3 -40 -11 -54 -12 -13 -19 -14 -22 -5 -2 7 -13 10 -26 6
-18 -4 -25 -1 -29 15 -3 11 1 26 10 32 8 7 12 21 9 31 -2 11 2 22 10 25 8 3
14 10 14 15 0 6 -6 5 -15 -2 -20 -17 -72 -3 -78 21 -4 15 0 19 18 19 19 0 22
4 17 23 -5 18 -7 19 -10 5 -2 -11 -13 -18 -28 -18 -22 0 -23 -2 -13 -21 9 -18
8 -25 -8 -40 -27 -27 -88 -10 -98 28 -8 33 9 45 55 39 30 -4 32 -3 15 6 -41
23 -47 58 -10 58 18 0 25 5 25 20 0 11 -4 20 -10 20 -5 0 -10 -7 -10 -15 0 -9
-9 -15 -25 -15 -27 0 -28 -3 -16 -35 18 -46 -81 -49 -121 -3 -27 30 -22 46 16
54 32 5 34 8 25 31 -13 34 -13 33 25 33 29 0 33 3 29 20 -3 11 -10 20 -15 20
-6 0 -8 -7 -4 -15 4 -11 -2 -15 -27 -15 -44 0 -111 40 -115 68 -3 21 -1 23 32
16 32 -5 36 -4 36 15 0 11 -4 21 -10 21 -5 0 -10 -6 -10 -13 0 -11 -10 -12
-42 -7 -66 11 -118 40 -118 66 0 17 -9 24 -45 34 -50 14 -65 28 -44 41 10 6 8
9 -8 9 -13 0 -23 -6 -23 -14 0 -8 -16 -16 -42 -19 -59 -8 -128 2 -128 19 0 8
8 14 17 14 9 0 14 3 10 6 -13 14 -57 -7 -57 -26 0 -17 7 -20 43 -20 46 0 67
-13 67 -41 0 -22 -49 -39 -111 -39 -38 0 -51 4 -59 19 -14 26 -13 41 3 41 7 0
22 7 33 15 17 13 15 13 -18 0 -50 -20 -128 -20 -128 0 0 8 -6 12 -14 9 -8 -3
-12 -13 -9 -25 5 -17 11 -19 54 -13 49 7 49 6 49 -22 0 -38 -47 -69 -120 -79
l-53 -7 6 38 c3 22 3 33 0 27 -2 -7 -11 -13 -19 -13 -10 0 -12 -8 -7 -31 5
-25 0 -36 -27 -64 -18 -18 -44 -36 -59 -40 -14 -3 -34 -8 -43 -11 -14 -4 -18
1 -18 20 0 31 -6 32 -45 6 -16 -11 -25 -20 -18 -20 6 0 15 5 18 10 10 16 22
11 28 -11 7 -26 -42 -98 -80 -118 -23 -12 -28 -11 -38 3 -11 15 -13 15 -26 -1
-9 -11 -10 -14 -1 -9 15 9 17 -36 1 -67 -10 -21 -10 -21 5 -3 9 11 16 25 16
31 0 6 15 19 34 28 l35 16 6 -28 c9 -41 -29 -114 -66 -128 -33 -13 -39 -9 -39
29 l0 28 -30 -32 c-16 -18 -30 -27 -30 -20 0 6 -4 12 -8 12 -5 0 -7 -13 -4
-30 l4 -30 20 25 c26 34 38 31 38 -7 0 -18 4 -28 8 -22 10 16 51 36 61 30 20
-13 21 -58 1 -101 -11 -25 -25 -45 -29 -45 -5 0 -16 -3 -25 -6 -11 -4 -19 2
-26 20 -11 30 -22 33 -41 9 -13 -15 -15 -13 -20 18 l-6 34 -2 -37 c-1 -41 11
-55 29 -33 17 21 27 19 34 -7 6 -26 -13 -99 -29 -110 -19 -14 -35 -8 -35 14 0
12 -2 19 -5 16 -3 -2 -2 -27 2 -54 5 -39 8 -44 13 -24 8 34 38 38 46 6 5 -21
10 -24 35 -19 25 5 30 2 39 -23 16 -41 -10 -94 -44 -94 -15 0 -26 7 -29 18 -2
9 -4 3 -4 -14 0 -37 -9 -54 -29 -54 -8 0 -14 -4 -14 -9 0 -19 41 3 46 25 9 34
50 33 66 -1 16 -35 -5 -74 -36 -69 l-21 5 22 -10 c25 -12 39 -49 24 -64 -6 -6
-11 -18 -11 -27 1 -12 2 -12 11 3 5 9 16 17 24 17 11 0 10 7 -5 33 -20 33 -20
34 -1 51 31 28 74 14 90 -29 10 -30 10 -37 -3 -51 -10 -10 -24 -13 -38 -9 -20
5 -21 4 -5 -5 9 -6 24 -24 32 -40 14 -27 14 -29 -8 -32 -23 -4 -77 20 -77 34
0 4 -9 8 -19 8 -18 0 -72 60 -54 60 4 0 3 4 -3 8 -16 10 -44 68 -44 90 0 10
-4 22 -9 27 -12 14 -23 77 -12 70 6 -3 4 7 -4 22 -17 36 -19 226 -2 216 7 -4
6 0 -1 9 -14 18 -7 103 9 114 5 3 9 17 9 31 0 34 49 143 64 140 6 -1 11 7 11
18 1 19 21 68 37 90 37 50 57 69 58 53 0 -10 5 -18 11 -18 6 0 9 9 6 20 -4 15
8 34 40 68 25 26 50 46 54 45 5 -2 9 4 9 12 0 8 4 15 9 15 5 0 16 6 23 13 23
20 78 47 99 47 10 0 19 5 19 12 0 6 3 8 7 5 3 -4 11 -1 17 6 12 15 125 51 133
44 2 -3 19 1 36 8 39 17 239 19 314 3z m13 -62 c22 -8 36 -20 38 -33 3 -17 -4
-22 -35 -26 -79 -10 -151 25 -128 63 8 14 82 11 125 -4z m110 -86 c30 -15 40
-27 40 -44 0 -19 -6 -25 -32 -29 -49 -7 -121 24 -129 56 -5 20 -3 27 10 30 37
10 74 6 111 -13z m-343 -4 c2 -1 5 -16 8 -32 5 -25 1 -32 -29 -51 -25 -16 -51
-23 -85 -23 -50 0 -50 0 -57 36 -5 26 -3 39 7 45 8 4 23 15 34 24 15 12 32 14
70 10 27 -4 51 -7 52 -9z m-188 -78 c11 -31 10 -34 -25 -65 -38 -31 -109 -52
-125 -36 -5 5 -11 22 -14 38 -4 26 1 34 36 63 30 23 51 32 79 32 34 0 39 -4
49 -32z m472 3 c16 -6 31 -22 37 -41 11 -29 10 -31 -24 -47 -30 -15 -41 -15
-82 -4 -25 6 -48 14 -50 15 -1 2 -5 19 -9 39 -5 31 -2 36 23 45 31 12 63 10
105 -7z m-161 -16 c5 -3 12 -19 15 -36 6 -27 3 -35 -21 -55 -30 -24 -59 -29
-112 -18 -25 5 -32 13 -40 45 -9 37 -8 39 26 62 31 20 43 22 80 15 24 -4 47
-10 52 -13z m324 -1 c32 -16 51 -47 40 -65 -10 -17 -63 -20 -98 -5 -40 17 -67
63 -44 78 21 13 65 9 102 -8z m-549 -34 c30 0 35 -4 45 -36 12 -34 11 -37 -21
-70 -29 -30 -39 -34 -84 -34 -49 0 -51 1 -64 36 -13 35 -12 37 25 75 21 22 45
37 52 34 8 -3 29 -5 47 -5z m-271 -26 c12 -32 5 -60 -24 -93 -23 -26 -38 -34
-68 -35 -33 -1 -37 2 -40 26 -4 37 36 96 73 107 44 13 52 13 59 -5z m618 -40
c13 -4 31 -44 33 -75 0 -3 -14 -18 -31 -32 -28 -23 -34 -25 -77 -15 -43 9 -48
13 -59 50 -12 39 -11 42 18 65 28 23 55 25 116 7z m277 -89 c39 -20 47 -54 18
-83 -20 -19 -30 -22 -64 -16 -41 6 -63 31 -63 71 0 39 60 54 109 28z m-629
-47 l18 -42 -33 -40 c-32 -39 -35 -41 -83 -38 -46 4 -52 7 -61 34 -17 47 -14
59 21 95 26 27 39 33 75 33 42 0 45 -2 63 -42z m460 32 c28 -8 39 -18 48 -44
12 -33 11 -37 -14 -65 -18 -21 -34 -29 -48 -26 -73 14 -76 16 -91 52 -15 36
-15 37 12 65 29 31 40 33 93 18z m-770 -35 c8 -26 8 -40 -1 -57 -23 -45 -52
-68 -85 -68 -28 0 -33 4 -39 30 -5 22 0 41 17 72 22 40 32 47 90 57 3 0 12
-15 18 -34z m1043 -34 c60 -23 33 -100 -35 -101 -33 0 -38 3 -48 36 -11 32
-10 37 11 55 25 21 37 23 72 10z m-914 -52 c12 -38 11 -43 -15 -80 -24 -35
-31 -39 -71 -39 -38 0 -45 3 -53 26 -16 40 -12 63 16 100 23 29 32 34 69 34
40 0 42 -1 54 -41z m277 21 c30 0 36 -5 49 -37 19 -44 14 -68 -20 -106 -22
-25 -28 -27 -78 -21 -53 6 -53 7 -66 50 -11 36 -11 47 0 62 34 45 54 62 67 57
7 -3 29 -5 48 -5z m524 -20 c26 -26 25 -54 -1 -89 -19 -26 -26 -28 -63 -24
-49 7 -42 2 -61 46 -15 35 -14 37 12 62 34 31 85 33 113 5z m222 -27 c5 -22
-9 -65 -25 -75 -25 -16 -54 -8 -65 18 -10 21 -9 29 5 49 22 30 79 35 85 8z
m-1181 -71 c10 -35 9 -43 -12 -76 -21 -32 -28 -36 -59 -34 -50 4 -62 42 -32
103 20 40 25 45 56 45 32 0 36 -3 47 -38z m124 -87 l18 -45 -27 -35 c-23 -30
-33 -35 -69 -35 -40 0 -43 2 -55 40 -11 37 -10 44 10 80 21 36 27 40 65 40 40
0 42 -2 58 -45z m-148 -80 c16 -34 16 -38 -2 -70 -16 -30 -23 -35 -55 -35 -32
0 -37 4 -49 35 -9 27 -9 39 0 57 20 40 31 48 60 48 24 0 33 -7 46 -35z m253
15 c21 0 29 -8 43 -46 l17 -46 -28 -34 c-45 -56 -122 -43 -143 25 -11 36 -10
41 16 76 22 27 34 35 49 31 12 -3 32 -6 46 -6z m448 -22 c17 -17 17 -249 0
-266 -14 -14 -186 -16 -208 -2 -12 7 -15 34 -15 135 0 101 3 128 15 135 22 14
194 12 208 -2z m192 -103 l0 -115 75 0 75 0 0 -30 0 -30 -105 0 -105 0 0 145
0 145 30 0 30 0 0 -115z m320 0 l0 -115 50 0 50 0 0 115 0 115 30 0 31 0 -3
-142 -3 -143 -99 -3 c-64 -2 -103 1 -112 9 -11 9 -14 43 -14 145 l0 134 35 0
35 0 0 -115z m384 33 c17 -43 34 -78 37 -78 3 0 21 36 40 80 33 76 35 80 67
80 l32 0 0 -145 0 -145 -32 0 -33 0 2 87 c3 93 2 93 -36 1 -14 -34 -26 -48
-39 -48 -14 0 -24 14 -37 48 -28 75 -35 72 -35 -11 l0 -77 -35 0 -35 0 0 146
0 145 36 -3 c36 -3 38 -5 68 -80z m506 52 l0 -30 -70 0 c-68 0 -70 -1 -70 -25
0 -23 3 -25 50 -25 49 0 50 -1 50 -30 0 -29 -1 -30 -50 -30 -49 0 -50 -1 -50
-30 l0 -30 70 0 70 0 0 -30 0 -30 -105 0 -105 0 0 145 0 145 105 0 105 0 0
-30z m198 -15 c15 -25 29 -45 32 -45 3 0 18 20 33 45 26 41 32 45 68 45 45 0
46 3 -21 -89 l-39 -54 50 -74 50 -73 -39 0 c-35 0 -42 4 -68 45 -15 25 -30 45
-33 45 -4 0 -18 -21 -33 -45 -25 -42 -30 -45 -68 -45 l-42 0 44 63 c25 34 47
67 51 73 4 6 -16 44 -43 83 l-51 71 41 0 c37 0 43 -4 68 -45z m490 18 c-3 -25
-7 -28 -45 -31 l-43 -3 0 -115 0 -114 -35 0 -35 0 0 115 0 115 -40 0 c-38 0
-40 2 -40 30 l0 30 121 0 120 0 -3 -27z m-2383 -79 c19 -5 55 -62 55 -87 0 -7
-13 -27 -29 -46 -27 -32 -31 -33 -78 -26 -44 6 -52 10 -66 41 -23 47 -21 63
10 96 27 28 62 35 108 22z m-420 -60 c14 -33 13 -37 -6 -63 -45 -61 -130 5
-91 71 13 22 24 28 49 28 28 0 35 -5 48 -36z m247 -10 c9 -3 24 -24 33 -48
l17 -43 -28 -28 c-24 -24 -32 -26 -70 -20 -35 5 -47 12 -59 35 -20 39 -19 56
6 92 22 30 32 31 101 12z m-174 -50 c23 -5 33 -15 45 -46 16 -39 16 -40 -9
-63 -21 -20 -30 -22 -57 -14 -18 5 -33 9 -34 9 -2 0 -9 16 -18 36 -13 32 -13
38 3 60 19 25 24 27 70 18z m620 -166 c16 -16 15 -43 -3 -58 -13 -11 -13 -15
0 -33 18 -25 19 -37 5 -37 -6 0 -16 11 -22 25 -15 32 -36 32 -40 0 -7 -54 -23
-27 -26 43 l-3 72 39 0 c21 0 43 -5 50 -12z m272 2 c0 -5 -14 -10 -31 -10 -24
0 -30 -4 -27 -17 2 -10 10 -19 18 -19 8 -1 21 -2 28 -3 23 -2 2 -21 -24 -21
-17 0 -24 -5 -24 -20 0 -16 7 -20 30 -20 17 0 30 -4 30 -10 0 -5 -18 -10 -40
-10 l-40 0 0 70 0 70 40 0 c22 0 40 -4 40 -10z m280 1 c0 -6 -15 -11 -32 -13
-69 -7 -68 -89 1 -96 41 -4 30 -22 -14 -22 -59 0 -88 77 -45 120 21 21 90 29
90 11z m200 -16 c0 -21 5 -25 30 -25 25 0 30 4 30 25 0 16 6 25 15 25 12 0 15
-14 15 -70 0 -56 -3 -70 -15 -70 -10 0 -15 11 -15 35 0 32 -2 35 -30 35 -28 0
-30 -3 -30 -35 0 -24 -5 -35 -15 -35 -12 0 -15 14 -15 70 0 56 3 70 15 70 9 0
15 -9 15 -25z m351 -37 c13 -35 25 -66 27 -70 2 -5 -3 -8 -11 -8 -8 0 -17 9
-20 20 -7 28 -50 26 -70 -2 -27 -41 -27 -14 0 55 15 37 32 67 39 67 6 0 22
-28 35 -62z m273 56 c19 -7 21 -45 4 -62 -10 -10 -8 -19 5 -42 14 -24 15 -30
4 -30 -7 0 -20 11 -27 25 -7 14 -19 25 -27 25 -7 0 -13 -11 -13 -25 0 -14 -4
-25 -10 -25 -6 0 -10 30 -10 70 l0 70 29 0 c16 0 36 -3 45 -6z m306 -4 c0 -5
-16 -10 -35 -10 -26 0 -39 -6 -51 -25 -15 -23 -15 -27 0 -50 21 -32 66 -35 66
-5 0 11 -7 20 -15 20 -8 0 -15 5 -15 10 0 6 11 10 25 10 23 0 25 -4 25 -40 l0
-40 -44 0 c-35 0 -48 5 -60 22 -39 55 -4 118 64 118 22 0 40 -4 40 -10z m265
0 c-3 -5 -19 -10 -36 -10 -40 0 -39 -28 1 -36 17 -4 30 -10 30 -15 0 -5 -13
-9 -30 -9 -23 0 -30 -4 -30 -20 0 -15 7 -20 29 -20 17 0 33 -4 36 -10 4 -6
-12 -10 -39 -10 l-46 0 0 70 0 70 46 0 c27 0 43 -4 39 -10z"/>
<path d="M2076 3346 c3 -8 -2 -16 -13 -19 -10 -3 -4 -5 15 -6 36 -1 41 8 12
26 -16 11 -18 10 -14 -1z"/>
<path d="M1474 3304 c-18 -14 -18 -15 4 -4 12 6 22 13 22 15 0 8 -5 6 -26 -11z"/>
<path d="M1318 3245 c-4 -16 -1 -22 6 -17 6 4 27 13 46 19 l35 13 -32 -5 c-18
-2 -33 0 -33 5 0 21 -18 9 -22 -15z"/>
<path d="M2250 3240 c19 -13 30 -13 30 0 0 6 -10 10 -22 10 -19 0 -20 -2 -8
-10z"/>
<path d="M1279 3193 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M2330 3160 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2410 3070 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2500 2886 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"/>
<path d="M1061 2484 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1011 2453 c-1 -6 3 -14 8 -17 12 -8 41 3 41 15 0 6 -8 5 -19 -1 -14
-7 -21 -6 -24 3 -4 9 -6 9 -6 0z"/>
<path d="M1012 2380 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1058 2307 c-3 -19 -2 -25 4 -19 6 6 8 18 6 28 -3 14 -5 12 -10 -9z"/>
<path d="M1800 2435 l0 -85 50 0 50 0 0 85 0 85 -50 0 -50 0 0 -85z"/>
<path d="M1880 2160 c0 -13 7 -20 18 -20 24 0 32 8 25 26 -8 22 -43 17 -43 -6z"/>
<path d="M2997 2153 c-12 -12 -7 -33 8 -33 17 0 19 9 7 28 -5 7 -11 9 -15 5z"/>
<path d="M3260 2155 c0 -28 8 -31 35 -12 21 16 12 37 -16 37 -14 0 -19 -7 -19
-25z"/>
</g>
</svg>



    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}